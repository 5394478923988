<template>
  <v-sheet
    ref="hour-picker"
    :width="width"
    :max-height="height"
    class="hour-picker overflow-y-auto pb-8"
    :class="{ 'disabled': disabled }"
    @mousemove="whileDragging"
    @mouseup="endDrag()"
    @mouseleave.native="cancelDrag()"
  >
    <div class="hour-picker-grid d-flex align-stretch px-2">
      <div class="hour-picker-labels pt-7 px-2 text-right flex-shrink-0">
        <div 
          v-for="h in grid.hours"
          :key="`${h}-hour-left-label`"
          :class="[`${h}-hour-left-label`]"
          class="hour-label py-1 text-overline text--disabled"
        >
          {{ h }}
        </div>
      </div>
      <div class="hour-picker-columns d-flex align-stretch flex-grow-1">
        <div 
          v-for="d in grid.days"
          :key="`${d.value}-column`"
          class="hour-picker-column text-center flex-grow-1"
        >
          <header class="hour-picker-header py-2 text-overline text--disabled">
            {{ d.text }}
          </header>

          <div 
            class="hour-picker-group-container"
            @mousedown="startDrag('new', d.value, null, null, $event)"
          >
            <div 
              class="hour-picker-group pa-1"
              v-for="(group, g) in display[d.value]"
              :key="`${d}-${g}-group`"
              :style="{ 'top': (group.start * 40) + 'px', 'height': ((group.end - group.start + 1) * 40) + 'px' }"
            >
              <v-hover
                v-slot="{ hover }"
              >
                <v-card
                  dark
                  :color="disabled ? 'grey lighten-1' : 'primary'"
                  elevation="0"
                  min-height="32"
                  :class="{ 'active elevation-1': controller.group!=null && controller.group.day==d.value && controller.group.g==g }"
                  class="hour-picker-card text-overline"
                  @mousedown.stop="startDrag('move', d.value, g, group, $event)"
                >
                  <v-sheet 
                    color="white"
                    class="hour-picker-card-grabber start"
                    :class="{ 'active': controller.group!=null && controller.group.day==d.value && controller.group.g==g && controller.action=='start' }"
                    @mousedown.stop="startDrag('start', d.value, g, group, $event)"
                  />
                  {{ group.text }}
                  <v-sheet 
                    color="white"
                    class="hour-picker-card-grabber end"
                    :class="{ 'active': controller.group!=null && controller.group.day==d.value && controller.group.g==g && controller.action=='end' && controller.action=='new' }"
                    @mousedown.stop="startDrag('end', d.value, g, group, $event)"
                  />
                  <v-btn
                    v-show="hover"
                    fab 
                    depressed
                    x-small
                    color="primary"
                    class="hour-picker-card-remove ml-0"
                    @mousedown.stop
                    @click.stop="remove(d.value, g)"
                  >
                    <v-icon small>{{ icons.delete }}</v-icon>
                  </v-btn>
                </v-card>
              </v-hover>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<style>

  .hour-picker {
    position: relative;
  }
  .hour-picker * {
    user-select: none;
    -webkit-user-select: none;
  }
  .hour-picker.disabled * {
    pointer-events: none;
  }
  .hour-picker.disabled .hour-picker-grid {
    opacity: .8;
  }

  .hour-picker .hour-picker-header {
    position: sticky;
    top: 0;
    z-index: 5;
    background: rgba(255, 255, 255, .9);
  }

  .hour-picker .hour-picker-columns {
    width: 100%;
    min-height: 100%;
  }
  .hour-picker .hour-picker-column {
    flex-basis: 0;
  }
  .hour-picker .hour-picker-column:not(:last-child) {
    border-right: 1px solid var(--light-border);
  }

  .hour-picker-group-container {
    position: relative;
    width: 100%;
    height: calc(100% - 64px);
  }

  .hour-picker-group {
    position: absolute;
    width: 100%;
  }
  .hour-picker-card {
    height: 100%;
    cursor: grab;
    user-select: none;
    -webkit-user-select: none;
  }
  .hour-picker-card.active {
    opacity: .8;
  }
  .hour-picker-card-grabber {
    position: absolute;
    left: 0;
    right: 0;
    height: 8px;
    cursor: ns-resize;
    opacity: 0;
    will-change: opacity;
    transition: opacity .15s ease;
  }
  .hour-picker-card-grabber.start {
    top: 0;
  }
  .hour-picker-card-grabber.start:hover, .hour-picker-card-grabber.start.active {
    top: -8px;
  }
  .hour-picker-card-grabber.end {
    bottom: 0;
  }
  .hour-picker-card-grabber.end:hover, .hour-picker-card-grabber.end.active {
    bottom: -8px;
  }
  .hour-picker-card-grabber:hover, .hour-picker-card-grabber.active {
    left: -4px;
    right: -4px;
    height: 16px;
    opacity: .4;
  }

  .hour-picker-card-remove {
    position: relative;
    z-index: 1;
  }

</style>

<script>
  import { mdiTrashCan } from '@mdi/js';

  export default {
    props: {
      selected: {
        type: Object,
        default: () => {
          return {
            format: '%W %k',
            regex: '(^(Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday) (0|1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|21|22|23)$)'
          }
        }
      },
      loading: {
        type: Boolean,
        default: false
      },
      width: {
        type: [String, Number],
        default: 288
      },
      height: {
        type: [String, Number],
        default: '100%'
      },
      disabled: {
        type: Boolean,
        default: false
      },
    },
    
    data: () => ({
      icons: {
        delete: mdiTrashCan,
      },
      controller: {
        group: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
      },
      display: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
      grid: {
        days: [
          {
            value: 'Monday', 
            index: 1, 
            text: 'Seg'
          }, 
          {
            value: 'Tuesday', 
            index: 2, 
            text: 'Ter'
          }, 
          {
            value: 'Wednesday', 
            index: 3, 
            text: 'Qua'
          }, 
          {
            value: 'Thursday', 
            index: 4, 
            text: 'Qui'
          }, 
          {
            value: 'Friday', 
            index: 5, 
            text: 'Sex'
          }, 
          {
            value: 'Saturday', 
            index: 6, 
            text: 'Sáb'
          }, 
          {
            value: 'Sunday', 
            index: 0, 
            text: 'Dom'
          }, 
        ],
        hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
      }
    }),

    computed: {
      days () {
        const base = this.grid.days;
        return _.map(base, day => {
          return {
            ...day,
            text: this.$moment().day(day.index).format('ddd')
          }
        })
      }
    }, 

    watch: {
      selected: {
        immediate: true,
        handler (selected) {
          this.display.selected = this.decode(selected.regex);
        }
      },
    },

    methods: {
      startDrag (action, day, g, group, $event) {
        // console.log('start drag', day, g, $event);
        let y;
        if (action=='new') {
          y = $event.clientY - $event.currentTarget.getBoundingClientRect().top;
          const start = _.floor(y / 40);
          group = {
            start: start,
            end: start,
            text: `${start}–${start+1}h`
          };
          this.display[day].push(group);
          g = this.display[day].length-1;
        }
        const state = _.assign({}, group);
        this.controller.group = { day, g, state };
        this.controller.action = action;
        this.controller.dragStart = parseInt($event.clientY);
      },
      whileDragging ($event) {
        const c = this.controller;
        if (c.dragStart!=null) {
          const diff = $event.clientY - c.dragStart;
          const group = this.display[c.group.day][c.group.g];
          const state = c.group.state;
          const hours = _.round(diff / 40);
          const limit = _.reduce(this.display[c.group.day], (l, g) => {
            l.start = g.end < group.start && g.end > l.start ? g.end+1 : l.start;
            l.end = g.start > group.end && g.start < l.end ? g.start-1 : l.end;
            return l;
          }, { start: 0, end: 23 });
          const updated = { 
            start: c.action=='start'||c.action=='move' ? _.clamp(state.start + hours, limit.start, limit.end) : state.start,
            end: c.action=='end'||c.action=='move'||c.action=='new' ? _.clamp(state.end + hours, limit.start, limit.end) : state.end,
          };
          group.start = updated.start;
          group.end = updated.end;
          group.text = `${group.start}–${group.end+1}h`;
          // console.log('while dragging', updated, limit);
        }
      },
      endDrag (cancel) {
        // console.log('end drag');
        if (this.controller.dragStart!=null) {
          if (_.isNil(cancel)||!cancel) {
            this.update();
          }
          this.controller.action = null;
          this.controller.group = null
          this.controller.dragStart = null
          this.controller.createEvent = null
          this.controller.createStart = null
          this.controller.extendOriginal = null
        }
      },
      cancelDrag () {
        // console.log('cancel drag');
        this.endDrag(true);
      },

      remove (day, group) {
        this.display[day].splice(group, 1);
        this.update();
      },

      decode (selected) {
        const groupBy = /\(\^(.+?)\$\)/g;
        const splitBy = /\(([^)]+)\)/g;
        const groups = _.map([...selected.matchAll(groupBy)], g => {
          const group = [...g[1].matchAll(splitBy)];
          const days = _.split(group[0][1], '|');
          const hours = _.map(_.split(group[1][1], '|'), h => parseInt(h)).sort((a, b) => a - b);
          return { days, hours };
        })
        this.display = _.mapValues(this.display, day => {
          return [];
        });
        _.each(groups, group => {
          _.each(group.days, d => {
            let g = {
              start: _.first(group.hours),
              end: _.last(group.hours),
              text: ''
            };
            g.text = `${g.start}–${g.end+1}h`;
            this.display[d].push(g);
          });
        });
        console.log(this.display);
      },

      encode () {
        const compiled = _.reduce(this.display, (compiled, day, d) => {
          let temp = _.groupBy(day, g => {
            return _.join(_.range(g.start, g.end+1), '|');
          });
          temp = _.mapValues(temp, (hours, h) => {
            return _.concat(d, _.has(compiled, h) ? compiled[h] : []);
          })
          return _.assign(compiled, temp);
        }, {});
        const regex = _.reduce(compiled, (regex, days, hours) => {
          return (regex.length > 0 ? regex + '|' : regex) + '(^(' + _.join(days, '|') + ') (' + hours + ')$)';
        }, '');
        // console.log('process update', regex);
        return regex;
      },

      update () {
        const selection = {
          regex: this.encode(),
          format: this.selected.format,
        };
        console.log('update', selection);
        this.$emit('update', selection);
      },
    },

    mounted () {
    }

  }
</script>